<template>
  <div class="main">
    <a-row>
      <a-col :span="8" :offset="2">
        <a-page-header title="修改密码"/>
      </a-col>
    </a-row>
    <!-- 菜单列表 -->
    <!-- 新增model -->
    <a-form-model ref="addForm" :rules="rules" :model="addForm" :label-col="{span: 2}" :wrapper-col="{span: 6}">
      <a-form-model-item label="旧密码" prop="oldpass">
        <a-input v-model="addForm.oldpass" placeholder="输入旧密码" allowClear/>
      </a-form-model-item>
      <a-form-model-item label="新密码" prop="newpass">
        <a-input v-model="addForm.newpass" placeholder="请输入新密码" allowClear/>
      </a-form-model-item>
      <a-form-model-item label="确认密码" prop="conpass">
        <a-input v-model="addForm.conpass" placeholder="请再次输入新密码" allowClear/>
      </a-form-model-item>
      <a-form-model-item label=" " :colon="false">
        <a-space size="large">
          <a-button icon="redo" @click="resetForm">重置</a-button>
          <a-button icon="save" type="primary" html-type="submit" :loading="postLoading" @click="postAddForm">保存</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //新增表单数据
      addForm: {oldpass: "",newpass: "",conpass: ""},
      //表单数据 数据校检规则
      rules: {
        oldpass: [{ required: true, message: "请输入旧密码", trigger: "blur", min:9 }],
        newpass: [{ required: true, message: "请输入新密码", trigger: "blur", min:9}],
        conpass: [{ required: true, message: "请再次输入新密码", trigger: "blur", min:9 }],
      },
      postLoading: false //提交loadding
    };
  },
  methods: {
    postAddForm() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.postLoading = true
          let data = new FormData();
          Object.keys(this.addForm).forEach(key => {
            data.append(key, this.addForm[key]);
          });
          this.$http
            .post("/admin/admin/resetPassword", data)
            .then(res => {
              //请求成功后的处理函数
              this.postLoading = false;
              this.$notification["success"]({
                message: "系统提示",
                description: res.data.msg,
                duration: 2.5,
                key: 2
              });
              this.resetForm()
            })
            .catch(() => {
              //请求失败后的处理函数
              this.postLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    resetForm(){
      this.$refs.addForm.resetFields()
    },
    randPassWord(){
        let pasArr = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','0','1','2','3','4','5','6','7','8','9','_','-','$','%','&','@','+','!'];
        let password = '';
        let pasArrLen = pasArr.length;
        for (let i=0; i<12; i++){
            let x = Math.floor(Math.random()*pasArrLen);
            password += pasArr[x];
        }
        this.addForm.user_pass = password
    }
  }
};
</script>

<style lang="scss" scope>
.footer {
  background-color: #ffffff;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  text-align: right;
}
.ant-tree-child-tree-open li {
  display: inline-table;
}
</style>
